import Keycloak from "keycloak-js";

const keycloakInstance = new Keycloak(
{
    "realm": "zohius",
    "url": "https://id.zohius.com/",
    "clientId": "backend"
});

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const Login = (onAuthenticatedCallback: Function) => {
  keycloakInstance
    .init({ onLoad: "login-required" })
    .then(function (authenticated) {
      authenticated ? onAuthenticatedCallback() : alert("non authenticated");
    })
    .catch((e) => {
      console.dir(e);
      console.log(`keycloak init exception: ${e}`);
    });
};

const UserName = () => {
  return keycloakInstance.tokenParsed?.preferred_username;
};

const GetUserRoles = () => {
  return keycloakInstance.tokenParsed?.realm_access?.roles;
};

const UserHasRole = (role: string) => {
    return keycloakInstance.hasRealmRole(role);
}

const GetToken = () => {
  return keycloakInstance.token;
};

const GetTokenParsed = () => {
  return keycloakInstance.tokenParsed;
};

const Logout = () => {
  keycloakInstance.logout();
}

const KeyCloakService = {
    CallLogin: Login,
    GetUserName: UserName,
    GetUserRoles: GetUserRoles,
    UserHasRole: UserHasRole,
    CallLogout: Logout,
    GetToken: GetToken,
    GetTokenParsed: GetTokenParsed
};

export default KeyCloakService;