import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './App.css';
import { UploadedImage } from './UploadedImage';
import KeyCloakService from './security/KeyCloakService';


function App() {
  const [fileAdded, setFileAdded] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/webp': []
    },
    onDrop: (acceptedFiles) => {
      // Do something with the files
      console.log(acceptedFiles);
      setUploadedImage(acceptedFiles[0]);
      setFileAdded(true);
    },
  });
  return (
    <div className="App">
      <header className="sticky top-0 flex justify-between items-center px-4 py-2 bg-gray-800 text-white">
        <h1 className="text-xl">Image Background Remover</h1>
        <p className={`${KeyCloakService.UserHasRole('image_process') ? 'text-blue-600' : ''}`}>Hi {KeyCloakService.GetUserName()}</p>
        <button onClick={KeyCloakService.CallLogout} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Logout
        </button>
      </header>

      {!fileAdded && (
        <header className="App-header">
          <div {...getRootProps()} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <input {...getInputProps()} />
            {
              isDragActive ?
                <p>Drop the image here ...</p> :
                <p>Drag 'n' drop png or jpeg image here, or click to select files</p>
            }
          </div>
        </header>
      )}

      <div style={{ height: '100vh', overflow: 'auto' }}>
        <UploadedImage file={uploadedImage} onRemove={() => {setUploadedImage(null); setFileAdded(false);}} />
      </div>

    </div>
  );
}

export default App;
