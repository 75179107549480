import React, { useState } from 'react';
import KeyCloakService from './security/KeyCloakService';

// Component to display the uploaded image
export const UploadedImage: React.FC<{ file: File | null; onRemove: () => void; }> = ({ file, onRemove }) => {
  
  const [processedImageURL, setProcessedImageURL] = useState<string | null>(null);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  if (!file) {
    return null;
  }

  // Create a URL for the file
  let fileURL: string | null = null;
  fileURL = URL.createObjectURL(file);

  async function removeBackground(): Promise<void> {
    // Show the spinner
    setShowSpinner(true);
    // Create a FormData object and append the file
    const formData = new FormData();
    formData.append('file', file as Blob);

    let jwt = KeyCloakService.GetToken();
    let tokenParsed = KeyCloakService.GetTokenParsed();

    // Send the file to the ImageUploadController
    // if development
    let apiURL = 'https://api.zohius.com/ImageUpload';
    // if (process.env.NODE_ENV === 'development') {
    //let apiURL = 'https://localhost:7290/ImageUpload';
    // }
    // // if production
    // else {
    //   apiURL = 'https://api.zohius.com/ImageUpload';
    // }

    const response = await fetch(apiURL , { 
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + jwt,
      },
      body: formData,
    });

    
    // Check if the request was successful
    if (!response.ok) {
      throw new Error('Failed to remove background');
    }

    // Get the processed image
    const processedImage = await response.blob();

    // Create a new object URL for the processed image and update the file
    //fileURL = URL.createObjectURL(processedImage);
    setProcessedImageURL(URL.createObjectURL(processedImage));

    
    // Hide the spinner
    setShowSpinner(false);
  }

  return (
    // Display the image and a button to remove the image on the right side of the screen
    <div className="flex flex-col items-center mt-4 max-w-full max-h-full ">
      <button onClick={() => {setProcessedImageURL(null); onRemove();}} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded">
        Clear
      </button>
      <div className=" md:flex">
        <div className="w-full md:w-1/2 p-1">
          <img src={fileURL} alt="Uploaded" className="object-contain w-auto h-auto" />
        </div>
        <div className="w-full md:w-1/2 p-1">
          {processedImageURL ?       
            <img src={processedImageURL ?? ''} alt="Processed" className=" object-contain w-auto h-auto" />
          :
            <div className="flex items-center justify-center h-full">
              {showSpinner ?
               <div className="spinner"></div>
              :
                <button onClick={removeBackground} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4">
                Remove Background
                </button>
              }
          </div>
          }
        </div>
      </div>
    </div>
  );
};
